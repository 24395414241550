export const PIN_QUERY = {
  staff: {
    model: 'User',
    query: {
      fields: ['id', 'full_name', 'image'],
      with: ['training:user_id,badge_number,badge_type,badge_expiry'],
    },
  },
  contractor_staff: {
    model: 'ContractorStaff',
    query: {
      fields: [
        'id',
        'contractor_id',
        'first_name',
        'last_name',
        'badge_number',
        'badge_type',
        'badge_expiry',
        'image',
      ],
      with: ['contractor:id,name'],
    },
  },
  qr: {
    model: 'StaffQr',
    query: {
      fields: ['id', 'code', 'staff_id', 'staff_type'],
      with: ['staff'],
      first: true,
    },
  },
};
