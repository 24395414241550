/* eslint-disable @typescript-eslint/no-explicit-any */
import { signal } from '@angular/core';
import { MutationData } from '../lib/websocket.type';

export class WebsocketMutation {
  public controller: string;
  public method: string;
  public data = signal<{ [key: string]: any }>({});

  constructor(controller: string, method: string, data?: any) {
    this.controller = controller;
    this.method = method;

    if (data) {
      this.setData(data);
    }
  }

  public clone() {
    return new WebsocketMutation(this.controller, this.method, {
      ...this.data(),
    });
  }

  public getMutation(): MutationData {
    return {
      [this.controller]: {
        method: this.method,
        data: this.data(),
      },
    };
  }

  public getData(key?: string) {
    if (!key)  return this.data();

    return this.data()[key];
  }

  public setData(data: any) {
    this.data.set(data);
  }

  public updateData(key: string, updateFn: (data: any) => any) {
    this.data.update((data) => {
      data[key] = updateFn(data[key]);

      return { ...data };
    });
  }
}
