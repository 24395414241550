import { Injectable, computed, signal } from '@angular/core';
import { SubscribedChannelData } from 'websocket';
import { JobData, PinData, PinQueryResult, Staff } from './pin.type';
import { PIN_QUERY } from './pin.query';

@Injectable({
  providedIn: 'root',
})
export class PinState {
  public readonly job = signal<JobData | undefined>(undefined);

  public readonly staff = signal({
    loading: false,
    data: signal<Staff | undefined>(undefined),
  });

  public readonly pin = signal<PinData>({ type: 'qr', value: '' });

  public readonly model = computed(() => PIN_QUERY[this.pin().type]);

  public readonly queries = computed<SubscribedChannelData>(() => {
    const type = this.pin().type;

    const model = this.model();

    if (type === 'qr') {
      return {
        [model.model]: {
          ...model.query,
          where: {
            code: this.pin().value as string,
          },
        },
      };
    }

    return {
      [model.model]: {
        ...model.query,
        id: this.pin().value,
      },
    };
  });

  public setStaff(response: PinQueryResult) {
    const model = this.model();

    const data = response[model.model];

    if (!data) return;

    if (model.model === 'User' || model.model === 'ContractorStaff') {
      if (model.model === 'User') {
        data.role = data.training?.role;
        data.badge_number = data.training?.badge_number;
        data.badge_type = data.training?.badge_type;
        data.badge_expiry = data.training?.badge_expiry;
        delete data.training;
      }

      if (!data.role) {
        data.role = 'Steward';
      }

      if (!data.image) {
        data.image = '/assets/img/profile.png';
      }

      this.staff().data.set(data);
    }

    if (model.model === 'StaffQr' && data.staff) {
      this.staff().data.set(data.staff);
    }
  }
}
